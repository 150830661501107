<template>
  <div id="mineIdCard">
    <!-- 请拍摄并上传需要清关人员身份证照片 -->
    <p class="title">{{language.l_165}}</p>
    <div class="upload-plan">
      <van-uploader class="upload-img f" :disabled="isDisabled" :after-read="uploader_img" name="f">
        <template v-slot:default>
          <van-image fit="contain" :src="f_path ? f_path : 'https://resource.meifanco.com/20200331/d2f6a4657b6eb55700aa664ff1831bc9.png'"/>
        </template>
      </van-uploader>
      <van-uploader class="upload-img r" :disabled="isDisabled" :after-read="uploader_img" name="r">
        <template v-slot:default>
          <van-image fit="contain" :src="r_path ? r_path : 'https://resource.meifanco.com/20200331/45bf5700c20da2c2fd23c4ab121dffd3.png'"/>
        </template>
      </van-uploader>
    </div>
    <div class="upload-info">
      <!-- 身份证信息 -->
      <p class='upload-title'>{{language.l_166}}</p>
      <van-cell-group :border="false">
        <!-- 姓名    身份证姓名(自动识别) -->
        <van-field :label="language.l_121" v-model="name" :placeholder="language.l_167" :border="false" disabled />
        <!-- 身份证    身份证号码(自动识别) -->
        <van-field :label="language.l_168" v-model="idCard" :placeholder="language.l_169" :border="false" disabled />
      </van-cell-group>
    </div>
    <div class="info-plan">
      <!-- 关联地址 -->
      <p class='upload-title'>{{language.l_170}}</p>
      <!-- 选择地址 -->
      <van-field @click="showPicker" v-model="addressName" :label="language.l_171" :border="false" disabled right-icon="arrow-down" />
    </div>
    <div class="info-plan">
      <!-- 身份证上传说明 -->
      <p class='upload-title'>{{language.l_172}}</p>
      <!-- 根据中国海关规定，为防止变相走私，证明包裹内物品确实为个人自用，个人包裹办理海关入境清关手续需要提交收件人身份证明。 -->
      <p class="upload-main"> - {{language.l_173}}</p>
      <!-- 海关相关规定请参考《中华人民共和国海关对进出境快件监管办法》第二十二条，或致电海关咨询：12360 -->
      <p class="upload-main"> - {{language.l_174}}</p>
    </div>
    <div class='info-plan'>
      <!-- 拍摄身份证要求 -->
      <p class='upload-title'>{{language.l_175}}</p>
      <!-- 大陆公民持有的本人有效二代身份证 -->
      <p class='upload-main'>{{language.l_176}}</p>
      <!-- 拍摄时确保身份证 -->
      <p class='upload-main'>{{language.l_177}}<span>{{language.l_178}}</span></p>
    </div>
    <div class="tools-plan">
      <div v-for="(item, index) in imagesPreview" :key="index" class="tools-plan-item" @click="showPreview(index)">
        <img :src="item" alt="">
        <!-- 标准 -->
        <p v-if="index == 0">{{language.l_179}}</p>
        <!-- 边框缺失 -->
        <p v-else-if="index == 1">{{language.l_180}}</p>
        <!-- 证件模糊 -->
        <p v-else-if="index == 2">{{language.l_181}}</p>
        <!-- 闪光强烈 -->
        <p v-else-if="index == 3">{{language.l_182}}</p>
      </div>
    </div>
    <!-- 确定上传 -->
    <div class="upload-btn"><van-button @click="uploadSubmit" :disabled="isDisabled" round block type="info">{{language.l_183}}</van-button></div>
    <van-popup v-model="pickerShow" position="bottom" get-container="#app">
      <!-- 关联地址 -->
      <van-picker show-toolbar :title="language.l_170" :columns="columns" :default-index="picker_index" @cancel="showPicker" @confirm="selectAddress"/>
    </van-popup>

    <van-image-preview v-model="openPreview" :start-position="startIndex" :loop="false" :images="imagesPreview"></van-image-preview>
  </div>
</template>

<script>
import {addRessList,uploadIdCard,editingIdCard} from '@api';
export default {
  data() {
    return {
      dataInfo: null,
      f_path: null,
      r_path: null,
      name: '', // 姓名
      idCard: '', // 身份证
      addressName: this.$store.getters.language.l_184,  //请选择关联地址
      pickerShow: false,
      picker_index: 0,
      columns: [],
      startIndex: 0,
      openPreview: false,
      imagesPreview: [
        'https://resource.meifanco.com/20200331/41ceaf8d504eb5c09f9c8e1861b74008.png',
        'https://resource.meifanco.com/20200331/616029e333a704770f36b9960b16ea0f.png',
        'https://resource.meifanco.com/20200331/9c91ec5bf4ba05ac1f207c6eafc81908.png',
        'https://resource.meifanco.com/20200331/64d8caabd98ebec64aaab72cf8a3dc9f.png'
      ],
      language: this.$store.getters.language
    };
  },
  created() {
    this.getData();
  },
  methods: {
    uploader_img(file, {name}) { // 上传图片
      let formData = new FormData();
      formData.append('file', file.file);
      formData.append('face', name == 'f' ? 1 : 0);
      uploadIdCard(formData).then(res => {
        this.$toast.success(this.language.l_185);  //识别成功
        if(name == 'f') {
          this.f_path = res.data.pic.url;
          this.name = res.data.name;
          this.idCard = res.data.identity_card;
        } else {
          this.r_path = res.data.pic.url;
        }
      }).catch(() => {
        this.$toast.fail(this.language.l_186);  //识别失败
        if(name == 'f') {
          this.f_path = null;
        } else {
          this.r_path = null;
        }
      })
    },
    showPicker() { // 显示/隐藏选择器
      this.pickerShow = !this.pickerShow;
    },
    selectAddress(name, index) { // 选择收货地址
      const {data} = this.dataInfo;
      const adrData = data[index];
      this.f_path = null;
      this.r_path = null
      this.picker_index = index;
      this.addressName = name;
      this.name = name;
      this.idCard = adrData.receiver_identity_card;
      this.pickerShow = false;
    },
    showPreview(index) { // 显示上传规则图片预览
      this.openPreview = true;
      this.startIndex = index
    },
    uploadSubmit() { // 提交上传
      const {f_path, r_path} = this;
      if(!(f_path&&r_path)) {
        this.$toast.fail(this.language.l_187);   //请上传完整的身份证
        return;
      }
      const {picker_index, name, idCard} = this;
      const pics = [
        {face: 1, url: f_path},
        {face: 0, url: r_path}
      ]
      const id = this.dataInfo.data[picker_index].id;
      const params = {
        id,
        receiver_name: name,
        receiver_identity_card: idCard,
        pics: pics
      }

      editingIdCard(id, params).then(() => {
        this.getData();
        this.$toast.success(this.language.l_188);  //上传成功
        this.resetData();
        this.$router.push('/address')
      })
    },
    resetData() { // 重置
      this.f_path = null;
      this.r_path = null;
      this.name = '';
      this.idCard = '';
      this.picker_index = 0;
      this.addressName = this.language.l_184  //请选择关联地址
    },
    getData() {
       addRessList().then((res) => {
        const { data } = res.data;
        let columns = [];
        data.forEach(item => columns.push(item.receiver_name));
        this.dataInfo = res.data;
        this.columns = columns;
      })
    }
  },
  computed: {
    isDisabled() {return this.name == "" ? true : false}
  }
}
</script>

<style lang="less" scoped>
#mineIdCard {
  position: relative;
  background-color: #fff;
  .safe_padding_bottom();
  .title {
    font-size: 12px;
    display: inline-block;
    padding: 10px;
  }
  .upload-plan {
    display: flex;
    justify-content: space-around;
    width: 100%;
    .upload-img {
      width: 45%;
      height: 120px;
      position: relative;
      padding-bottom: 37px;
      /deep/ .van-uploader__wrapper,
      /deep/ .van-uploader__input-wrapper {
        width: 100%;
        height: 100%;
      }
      .van-image {
        display: block;
        width: 100%;
        height: 100%;
      }
      &.f,
      &.r {
        &::after {
          display: inline-block;
          position: absolute;
          text-align: center;
          width: 100%;
          bottom: 2px;
          background-image: @grad_left;
          border-radius: 0px 0px 10px 10px;
          height: 35px;
          font-size: 14px;
          color: white;
          vertical-align: middle;
          line-height: 35px;
        }
      }
      &.f {
        &::after {
          content: "拍摄正面";
        }
      }
      &.r {
        &::after {
          content: "拍摄反面";
        }
      }
    }
  }
  .upload-info,
  .info-plan {
    padding: 10px;
    .upload-title {
      font-size: 14px;
      margin-bottom: 10px;
    }
    .upload-main {
      font-size: 12px;
      line-height: 1.5;
      color: #999999;
      span {
        color: red;
      }
    }
  }
  .tools-plan {
    display: flex;
    .tools-plan-item {
      box-sizing: border-box;
      width: 25%;
      padding: 0 10px 20px;
      img {
        display: block;
        width: 100%;
        height: 100%;

        object-fit: contain;
      }
      p {
        margin-top: -3px;
        font-size: 12px;
        text-align: center;
      }
    }
  }
  .upload-btn{
    padding: 10px;
  }
}
</style>